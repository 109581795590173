export const globalConst = {
  // 전파세기
  rssiWarningdBm: -90,
  rssiMinimum: -91,
  rssiMaximum: -140,
  //기본 이미지 크기
  defaultImageWidth: 320,
  defaultImageHeight: 144,
  //이미지 크기 제한
  limitImageWidth: 600,
  limitImageHeight: 300,
  //
}

export const globalText = {
  installInside: '내부설치',
  installOutside: '외부설치',
}

export enum meterReadEnum {
  ANALOG = '기계식 계량기',
  DIGITAL = '디지털 계량기',
  GAS = '도시가스 계량기',
}

/**
 * 임시 의성 분구 (다인, 비안, 사곡, 안사, 안평)
 */
export enum dividedDistrictUiseongEnum {
  DAIN = '다인면',
  BIAN = '비안면',
  SAGOK = '사곡면',
  ANSA = '안사면',
  ANPYEONG = '안평면',
}

/**
 * 수용가 번호에 해당 지자체에서 관리하는 번호 처럼 "-" 추가
 *
 * @param officeId : 해당 수용가 번호의 officeId
 * @param number : 해당 수용가 번호
 */
export const globalFormatWithHyphens = (officeId: any, number: any) => {
  const numLength: {[key: string]: number[]} = {
    '7300': [12],
    B300: [13],
    '4001': [14],
    '4002': [14],
    '8400': [13, 14], // 13자리와 14자리를 허용
    A800: [15],
  }

  const formatPatterns: {[key: string]: RegExp} = {
    '7300': /(\d{3})(\d{3})(\d{4})(\d{2})/,
    B300: /(\d{2})(\d{2})(\d{3})(\d{6})/,
    '4001': /(\d{1})(\d{2})(\d{3})(\d{3})(\d{5})/,
    '4002': /(\d{1})(\d{2})(\d{3})(\d{3})(\d{5})/,
    '8400': /(\d{2})(\d{3})(\d{4})(\d{4})/,
    A800: /(\d{3})(\d{3})(\d{6})(\d{3})/,
  }

  const formatReplacedPatterns: {[key: string]: string} = {
    '7300': '$1-$2-$3-$4',
    B300: '$1-$2-$3-$4',
    '4001': '$1-$2-$3-$4-$5',
    '4002': '$1-$2-$3-$4-$5',
    '8400': '$1-$2-$3-$4',
    A800: '$1-$2-$3-$4',
  }

  let numToString = number.toString()

  const requiredLengths = numLength[officeId]
  const pattern = formatPatterns[officeId]
  const replacedPattern = formatReplacedPatterns[officeId]

  if (!requiredLengths || !requiredLengths.includes(numToString.length) || !pattern) {
    return numToString
  }

  if (officeId === '8400' && numToString.length === 13) {
    return numToString.replace(pattern, replacedPattern)
  }

  if (officeId === '8400' && numToString.length === 14) {
    const formatted = numToString.slice(0, 13).replace(pattern, replacedPattern)
    const lastDigit = numToString.slice(13)
    return `${formatted}-${lastDigit}`
  }

  return numToString.replace(pattern, replacedPattern)
}

export const HyphensOfficeHandler = (id: any) => {
  const allowed = ['00', '7300', 'B300', '4001', '4002', '8400', 'A800']
  if (allowed.includes(id)) {
    return true
  }
  const idPrefix = id.slice(0, 2)
  const allowedPrefixes = allowed.map((item) => item.slice(0, 2))
  if (allowedPrefixes.includes(idPrefix)) {
    return true
  }

  return false
}

/**
 * 누수의심 계산 model 기존 버전 "1", 개선 버전 "2"
 */
export const leakModelController = {
  model: '2',
}

export const leakModelHandler = (serverName: any, officeID: any) => {
  switch (serverName) {
    case 'prod':
    case 'dev':
      switch (officeID) {
        case '5600':
        case '56':
          return '1'
        default:
          return '2'
      }

    default:
      return '2'
  }
}

/**
 * 엔티모어 장비 임시 방편  디폴트"1", 엔티모어 "2"
 */
export const entimoreController = {
  model: '1',
}

export const mergeImages = (
  originalImageUrl: string,
  revisionImageUrl: string
): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return reject('Canvas context not available')
    }

    const originalImg = new Image()
    originalImg.crossOrigin = 'anonymous'
    originalImg.src = originalImageUrl

    const revisionImg = new Image()
    revisionImg.crossOrigin = 'anonymous'
    revisionImg.src = revisionImageUrl

    originalImg.onload = () => {
      canvas.width = originalImg.width
      canvas.height = originalImg.height
      ctx.drawImage(originalImg, 0, 0, originalImg.width, originalImg.height)
      revisionImg.onload = () => {
        ctx.drawImage(revisionImg, 0, 0, revisionImg.width, revisionImg.height)
        resolve(canvas.toDataURL('image/png'))
      }

      revisionImg.onerror = () => {
        console.error(`Failed to load revision image from URL: ${revisionImageUrl}`)
        resolve(canvas.toDataURL('image/png'))
      }
    }

    originalImg.onerror = () => {
      reject(`Failed to load original image from URL: ${originalImageUrl}`)
    }
  })
}
