import {DataGridPro, GridRowId} from '@mui/x-data-grid-pro'
import {serverLocalName} from 'GLOBAL/envVariables'
import CustomerDetailInfoMdal from 'pages/common/CustomerDetailInfoMdal'
import CustomerTargetImageListModal from 'pages/common/CustomerTargetImageListModal'
import DeviceDetailInfoModal from 'pages/common/DeviceDetailInfoModal'
import ExcelDownLoadModal from 'pages/common/ExcelDownloadModal'
import {
  customerInfoBoardVersionColumns,
  customerInfoGyeryongBoardVersionColumns,
} from 'pages/customer/dataConfig/declearColumns'
import {customerInfoFilter} from 'pages/customer/dataConfig/declearFilters'
import {customerInfoBoardVersionGroup} from 'pages/customer/dataConfig/declearGroupingModel'
import {customerInfoBoardVersionRows} from 'pages/customer/dataConfig/declearRows'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
import {deleteCustomerList, getAsCauseList, getCustomerList} from 'utils/apiCalling/api'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {
  customerDetailModalClose,
  customerDetailModalOpen,
  deviceDetailModalClose,
  deviceDetailModalOpen,
  imageDetailModalClose,
  imageDetailModalOpen,
  onRowsSelectionHandler,
  scrollTop,
} from 'utils/func/justUtils'
import {toolTipText} from 'utils/toolTips/toolTipText'
import AsBatchOffcanvas from '../modules/asBatchOffcanvas'

const CustomerInfo = (props: any) => {
  const location = useLocation()
  const pageState = location?.state
  const [asReasonData, setAsReasonData] = useState<any>('')
  const [checkData, setCheckData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [customerDetailData, setCustomerDetailData] = useState<any>(null)
  const [deviceDetailData, setDeviceDetailData] = useState<any>()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const cooperInfo = useSelector((state: any) => state?.cooperInfo)
  const [causeList, setCauseList] = useState<any>(null)
  const [customerInfoList, setCustomerInfoList] = useState<any>(null)
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
  const [totalCount, setTotalCount] = useState<any>(null)
  const [filter, setFilter] = useState<any>(customerInfoFilter(pageState))
  const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false)
  const [deviceDetailOpen, setDeviceDetailOpen] = useState<boolean>(false)
  const [customerDetailOpen, setCustomerDetailOpen] = useState<boolean>(false)
  const [hiddenClick, setHiddenClick] = useState<boolean>(false)
  const [mergedImages, setMergedImages] = useState<{[key: string]: string}>({})

  const getCustomerInfo = async () => {
    await getCustomerList(
      officeInfo,
      cooperInfo,
      filter,
      async (res: any, count: any) => {
        setCustomerInfoList(res)
        setTotalCount(count)

        // const imagePromises = res.map(async (item: any, i: any) => {
        //   const originalImage = item.filePath === null ? noImage : `${envImagePath}${item.filePath}`
        //   const revisionImage =
        //     item.revisionPath !== null
        //       ? `${envImagePath}/device/imagerevision/${item.imei}/image`
        //       : null

        //   if (revisionImage !== null) {
        //     try {
        //       const mergedImage = await mergeImages(originalImage, revisionImage)
        //       return {id: i + 1, image: mergedImage}
        //     } catch (error) {
        //       return {id: i + 1, image: originalImage}
        //     }
        //   } else {
        //     return {id: i + 1, image: originalImage}
        //   }
        // })

        // const mergedResults = await Promise.all(imagePromises)
        // const newMergedImages = mergedResults.reduce((acc: any, curr: any) => {
        //   acc[curr.id] = curr.image
        //   return acc
        // }, {})
        // setMergedImages(newMergedImages)
      },
      (error: any) => {},
      () => {
        setLoading(false)
      }
    )
  }

  const deleteCustomerListCall = async () => {
    await deleteCustomerList(checkData, {
      success: async (res) => {
        await getCustomerInfo()
        setCheckData([])
        setSelectionModel([])
      },
      fail: (err) => {
        console.log(err)
      },
      loading: (bool: boolean) => {
        setLoading(bool)
      },
    })
  }
  const openImageDetailModal = () => imageDetailModalOpen(setImageDetailOpen)
  const openDeviceDetailModal = () => deviceDetailModalOpen(setDeviceDetailOpen)
  const openCustomerDetailModal = () => customerDetailModalOpen(setCustomerDetailOpen)
  const closeImageDetailModal = () => imageDetailModalClose(setImageDetailOpen)
  const closeDeviceDetailModal = () => deviceDetailModalClose(setDeviceDetailOpen)
  const closeCustomerDetailModal = () => customerDetailModalClose(setCustomerDetailOpen)

  const columnsHandler = (serverName: any) => {
    switch (serverName) {
      case 'gyeryong':
      case 'mokpo':
        return customerInfoGyeryongBoardVersionColumns(
          openImageDetailModal,
          openDeviceDetailModal,
          openCustomerDetailModal,
          setCustomerDetailData,
          setDeviceDetailData,
          userInfo,
          getCustomerInfo,
          hiddenClick,
          mergedImages
        )

      default:
        return customerInfoBoardVersionColumns(
          openImageDetailModal,
          openDeviceDetailModal,
          openCustomerDetailModal,
          setCustomerDetailData,
          setDeviceDetailData,
          userInfo,
          getCustomerInfo,
          hiddenClick,
          mergedImages
        )
    }
  }

  const columns: any = columnsHandler(serverLocalName)
  const columnGroupingModel = customerInfoBoardVersionGroup
  const rows = customerInfoBoardVersionRows(customerInfoList)

  useEffect(() => {
    scrollTop()
    getAsCauseList(setCauseList)
    getCustomerInfo()
  }, [
    filter?.efosStatus,
    filter?.deviceStatus,
    filter?.deviceType,
    filter?.batteryStatus,
    filter.rowPerPage,
    filter.page,
    filter?.recognizedType,
    officeInfo,
    cooperInfo,
  ])

  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerInfo.description}
        tooltipText={toolTipText.customerInfo.text}
        filter={filter}
        id={'customer-info'}
        setFilter={setFilter}
        searchCallback={getCustomerInfo}
        total={totalCount}
        control={['device', 'battery', 'efos', , 'deviceType', 'recognizedType']}
        actions={['delete', 'download', 'blank']}
        searchOptions={[
          'customerNumber',
          'customerName',
          'serialNumber',
          'address',
          'managerName',
          'installerName',
          'memo',
        ]}
        checkData={checkData}
        asValue={{
          asReasonData,
          setAsReasonData,
          setSelectionModel,
          setCheckData,
          causeList,
          setHiddenClick,
          hiddenClick,
        }}
        onDelete={deleteCustomerListCall}
        onCancel={null}
        setTypeName={null}
      >
        {/* <div
          className='d-flex flex-column'
          style={{
            minHeight: 'calc(100vh - 460px)',
          }}
        > */}
        <DataGridPro
          rowHeight={180}
          headerHeight={30}
          autoHeight={true}
          columnGroupingModel={columnGroupingModel}
          experimentalFeatures={{columnGrouping: true}}
          rows={rows}
          rowCount={rows?.length}
          columns={columns}
          disableColumnMenu
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids, selection) => {
            const selectedRowsData = ids.map((id) => rows.find((row: any) => row.id === id))
            onRowsSelectionHandler(ids, [
              [setCheckData, selectedRowsData],
              [setSelectionModel, ids],
            ])
          }}
          loading={loading}
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
        {/* </div> */}
      </MeterariumPageLayout>

      <CustomerTargetImageListModal
        data={deviceDetailData}
        open={imageDetailOpen}
        onClose={closeImageDetailModal}
      />
      <DeviceDetailInfoModal
        data={deviceDetailData}
        open={deviceDetailOpen}
        onClose={closeDeviceDetailModal}
        getList={() => getCustomerInfo()}
      />
      <CustomerDetailInfoMdal
        data={customerDetailData}
        open={customerDetailOpen}
        onClose={closeCustomerDetailModal}
        getList={getCustomerInfo}
      />
      <ExcelDownLoadModal type={'수용가 정보조회'} search={filter} />
      <AsBatchOffcanvas toast={toast} />
      <ToastContainer />
    </>
  )
}
export default CustomerInfo
