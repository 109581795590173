import {GridColumnGroupingModel} from '@mui/x-data-grid-pro'

/**
 * Grouping
 */
export const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customerInfo'}],
  },
  {
    groupId: '당월검침정보',
    headerAlign: 'center',
    description: '당월 검침내역에 관한 정보입니다.',
    children: [{field: 'checkInfo'}],
  },
  {
    groupId: '전월검침정보',
    headerAlign: 'center',
    description: '전월 검침내역에 관한 정보입니다.',
    children: [
      {field: 'befCheckInfo'},
      {field: 'bef_check_code'},
      {field: 'bef_check_value'},
      {field: 'bef_used'},
    ],
  },
  {
    groupId: '변경일',
    headerAlign: 'center',
    description: '변경내역에 관한 정보입니다.',
    children: [{field: 'trsDateInfo'}],
  },
  {
    groupId: '변경자',
    headerAlign: 'center',
    description: '변경내역에 관한 정보입니다.',
    children: [{field: 'trsInfo'}],
  },
]

export const chilgokColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customer_number'}],
  },

  {
    groupId: '전월검침정보',
    headerAlign: 'center',
    description: '전월 검침내역에 관한 정보입니다.',
    children: [{field: 'bef_image'}, {field: 'bef_check_value'}],
  },
  {
    groupId: '당월검침정보',
    headerAlign: 'center',
    description: '당월 검침내역에 관한 정보입니다.',
    children: [
      {field: 'check_value'},
      {field: 'image'},
      {field: 'updateCheckValue'},
      {field: 'now_used'},
    ],
  },
  {
    groupId: '변경일',
    headerAlign: 'center',
    description: '변경내역에 관한 정보입니다.',
    children: [{field: 'trsDateInfo'}],
  },
  {
    groupId: '변경자',
    headerAlign: 'center',
    description: '변경내역에 관한 정보입니다.',
    children: [{field: 'trsInfo'}],
  },
]

export const suncheonColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: '이름',
    headerAlign: 'center',
    description: '수용가에 관한 정보입니다.',
    children: [{field: 'customer_number'}],
  },

  {
    groupId: '전월검침정보',
    headerAlign: 'center',
    description: '전월 검침내역에 관한 정보입니다.',
    children: [{field: 'bef_image'}, {field: 'bef_check_value'}],
  },
  {
    groupId: '당월검침정보',
    headerAlign: 'center',
    description: '당월 검침내역에 관한 정보입니다.',
    children: [
      {field: 'image'},
      {field: 'check_code'},
      {field: 'now_usedgit'},
      {field: 'updateCheckValue'},
    ],
  },
  {
    groupId: '변경일',
    headerAlign: 'center',
    description: '변경내역에 관한 정보입니다.',
    children: [{field: 'trsDateInfo'}],
  },
  {
    groupId: '변경자',
    headerAlign: 'center',
    description: '변경내역에 관한 정보입니다.',
    children: [{field: 'trsInfo'}],
  },
]
