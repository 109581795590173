import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {Button, FormControl, InputLabel, MenuItem, Select, Slide, TextField} from '@mui/material'
import {CalendarPickerView, DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import {serverLocalName} from 'GLOBAL/envVariables'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {
  crebizDataPost,
  saeolDataEumseongPost,
  saeolDataPost,
  wimsDataPost,
} from 'utils/apiCalling/api'
import {playConfirm} from 'utils/CustomAlert/Alert'
import {validationAlert} from './../../../utils/CustomAlert/Alert'
declare interface String {
  getByteLength(length: number): string
}
const ChargeSelect = (props: any) => {
  const getByteLength = (str: any, length: any) => {
    const contents = str
    let strCharacter
    let intCharCount = 0
    let intContentsLength = contents.length
    let returnValue = ''

    for (let k = 0; k < intContentsLength; k++) {
      strCharacter = contents.charAt(k)
      if (escape(strCharacter).length > 4) intCharCount += 2
      else intCharCount++

      if (intCharCount > length) {
        break
      }
      returnValue += strCharacter
    }
    return returnValue
  }

  const serverName = serverLocalName
  const {setSelectStep, selectStep} = props
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [selectInfo, setSelectInfo] = useState<any>({
    selectProgram: '',
    selectDate: '',
  })

  const [file, setFile] = useState<File>()
  const [fileName, setFileName] = useState<string>('')

  const [fileData, setFileData] = useState<any>()

  const fileUploadHandler = async (e: any) => {
    const target = e.currentTarget
    const file = (target.files as FileList)[0]
    if (serverName == 'eumseong') {
      if (file) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const arrayBuffer = event.target?.result as ArrayBuffer

          const uint8Array = new Uint8Array(arrayBuffer)

          try {
            const decodedText = new TextDecoder('euc-kr').decode(uint8Array)

            const lines = decodedText.split('\n')
            const fileData: any[] = []
            lines.forEach((el: any, i: number) => {
              const hycheckName = ''
              const hycheckAddress = ''
              const office_code = officeInfo.value
              const check_date = getByteLength(el, 6) // 검침년월
              const city_code = getByteLength(el, 7).replace(getByteLength(el, 6), '') // 시군코드
              const customer_number_info = getByteLength(el, 25).replace(getByteLength(el, 7), '') // 자원코드+부번호 포함
              let customer_number = customer_number_info?.slice(0, 15) // 수용가번호

              const check_date_file = getByteLength(el, 27).replace(getByteLength(el, 25), '') // 정기 검침일

              const customer_name = getByteLength(el, 57).replace(getByteLength(el, 27), '').trim() // 이름
              const address = getByteLength(el, 113).replace(getByteLength(el, 57), '').trim() // 주소
              const water_type = getByteLength(el, 116).replace(getByteLength(el, 113), '') // 상수업종
              const three_bef_code = getByteLength(el, 118).replace(getByteLength(el, 116), '') // 3개월 전 검침 코드
              const three_bef_value = getByteLength(el, 131)
                .replace(getByteLength(el, 118), '')
                .trim() // 3개월 전 지짐값
              const three_bef_used = getByteLength(el, 144)
                .replace(getByteLength(el, 131), '')
                .trim() // 3개월 전 사용량
              const two_bef_code = getByteLength(el, 146).replace(getByteLength(el, 144), '') // 2개월 전 검침 코드
              const two_bef_value = getByteLength(el, 159)
                .replace(getByteLength(el, 146), '')
                .trim() // 2개월 전 지짐값
              const two_bef_used = getByteLength(el, 172).replace(getByteLength(el, 159), '').trim() // 2개월 전 사용량
              const bef_check_code = getByteLength(el, 174).replace(getByteLength(el, 172), '') // 1개월 전 검침 코드
              const bef_check_value = getByteLength(el, 187)
                .replace(getByteLength(el, 174), '')
                .trim() // 1개월 전 지짐값
              const bef_used = getByteLength(el, 200).replace(getByteLength(el, 187), '').trim() // 1개월 전 사용량
              const memo = getByteLength(el, 260).replace(getByteLength(el, 200), '').trim() // 메모
              const households = getByteLength(el, 269).replace(getByteLength(el, 260), '').trim() // 가구수
              const phone_number = getByteLength(el, 299)
                .replace(getByteLength(el, 269), '')
                .trim()
                .replace(',', ' ') // 연락처
              const meter_diameter = getByteLength(el, 309)
                .replace(getByteLength(el, 299), '')
                .trim() // 구경
              const meter_number = getByteLength(el, 327).replace(getByteLength(el, 309), '').trim() // 계량기번호
              const water_number = getByteLength(el, 347).replace(getByteLength(el, 327), '').trim() // 수전번호
              const unknown_data1 = getByteLength(el, 358)
                .replace(getByteLength(el, 347), '')
                .trim() // 출수량
              const unknown_data2 = getByteLength(el, 359)
                .replace(getByteLength(el, 358), '')
                .trim() // 계량기계산식적용여부
              const unknown_data3 = getByteLength(el, 360)
                .replace(getByteLength(el, 359), '')
                .trim() // 지하수계량기여부
              const unknown_data4 = getByteLength(el, 362)
                .replace(getByteLength(el, 360), '')
                .trim() // 알수없음
              const meter_location = getByteLength(el, 462)
                .replace(getByteLength(el, 362), '')
                .trim() // 계량기 위치
              const demolition_value = getByteLength(el, 475)
                .replace(getByteLength(el, 462), '')
                .trim() // 철거 지침
              const install_value = getByteLength(el, 488)
                .replace(getByteLength(el, 475), '')
                .trim() // 설치 지침
              const unknown_data5 = getByteLength(el, 500)
                .replace(getByteLength(el, 488), '')
                .trim() // 알수없음
              const now_check_code = '00'
              // const check_value = ''
              // const check_value_default = ''
              // const now_check_date = ''
              // const now_used = ''
              // const as_status = ''
              const check_user = userInfo?.name

              fileData?.push({
                hycheckName, // Hycheck 이름
                hycheckAddress, // Hycheck 주소
                office_code,
                check_date, // 검침년월
                city_code, // 시군코드
                customer_number_info, // 수용가번호 (자원+부 포함)
                customer_number, // 수용가번호
                check_date_file, // 정기 검침일
                customer_name, // 이름
                address, // 주소
                water_type, // 상수업종
                three_bef_code, // 3개월 전 검침코드
                three_bef_value, // 3개월 전 지침값
                three_bef_used, // 3개월 전 사용량
                two_bef_code, // 2개월 전 검침코드
                two_bef_value, // 2개월 전 지침값
                two_bef_used, // 2개월 전 사용량
                bef_check_code, // 1개월 전 검침코드
                bef_check_value, // 1개월 전 지침값
                bef_used, // 1개월 전 사용량
                // memo, // 메모
                // households, // 가구수
                phone_number, // 연락처
                meter_diameter, // 구경
                // meter_number, // 계량기번호
                // water_number, // 수전번호
                // unknown_data1, // 출수량
                // unknown_data2, // 계량기계산식적용여부
                // unknown_data3, // 지하수계량기여부
                // unknown_data4, // 알수없음
                // meter_location, // 계량기 위치
                // demolition_value, // 철거 지침
                // install_value, // 설치 지침
                // unknown_data5 // 알수없음
                now_check_code,
                // check_value,
                // check_value_default,
                // now_check_date,
                // now_used,
                // as_status,
                check_user,
              })

              setFileData(fileData)
            })
          } catch (error) {
            console.error('Decoding error:', error)
          }
        }
        reader.readAsArrayBuffer(file)
      } else {
        console.error('No file selected')
      }

      setFile(file)
      setFileName(file.name)
    } else {
      console.log('file', file)
      setFile(file)
      setFileName(file.name)
    }
  }

  const postFileData = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('id', userInfo?.userName)
    formData.append('officeId', userInfo?.officeId)
    try {
      let res

      switch (serverName) {
        case 'eumseong':
          res = await saeolDataEumseongPost(fileData, selectInfo?.selectDate, officeInfo)
          break
        case 'cheonan':
        case 'suncheon':
          res = await wimsDataPost(formData, selectInfo?.selectDate, officeInfo)
          break

        case 'prod':
        case 'dev':
          switch (officeInfo?.value) {
            case 'B700':
            case 'B7':
              res = await crebizDataPost(formData, selectInfo?.selectDate, officeInfo)
              break

            default:
              break
          }
          break

        default:
          res = await saeolDataPost(formData, officeInfo)
          break
      }

      if (res) {
        if (fileName === '') {
          validationAlert('txt파일 업로드 없이 진행합니다.', 'success')
        } else {
          validationAlert(`'${fileName} 으로 요금연계를 진행합니다.'`, 'success')
        }
        setLoading(false)
      } else {
        setLoading(false)
        await validationAlert('실패했습니다', 'warning')
      }

      return true
    } catch (error) {
      console.error(error)
    }
  }

  /**
   *
   * @param selectedCase 특정 행동 ("페이지 시작시")
   * @param server env에 적힌 지역
   * @param officeValue 사업소 선택
   * @returns 페이지 시작시 해당 서버인 경우 요금프로그램 선택 // 미포함 서버의 경우 요금연계 프로그램 선택 프로세스
   */

  const selectStepHandler = (selectedCase: string, server: string, officeValue: string) => {
    switch (selectedCase) {
      case 'start':
        if (selectInfo.selectDate === '' && selectInfo.selectProgram === '') {
          setSelectStep({step1: false, step2: false})
        }
        switch (server) {
          case 'boseong':
            setSelectInfo({...selectInfo, selectProgram: 'SAEOL'})
            setSelectStep({step1: true, step2: false})
            return
          case 'cheonan':
          case 'suncheon':
            setSelectInfo({...selectInfo, selectProgram: 'WIMS'})
            setSelectStep({step1: true, step2: false})
            return
          case 'eumseong':
            setSelectInfo({...selectInfo, selectProgram: 'SAEOL'})
            setSelectStep({step1: true, step2: false})
            return
          case 'dev':
          case 'prod':
            switch (officeValue) {
              case 'B7':
              case 'B700': //칠곡
                setSelectInfo({...selectInfo, selectProgram: 'CREBIZ'})
                setSelectStep({step1: true, step2: false})
                return

              default:
                return
            }

          default:
            return
        }

      case 'something':
        return null

      default:
        return null
    }
  }

  /****************************************************************
   *
   * @param program 요금 프로그램
   * @returns 프로그램에 맞는 해당 문구
   */
  const selectedProgram = (program: any) => {
    switch (program) {
      case 'WIMS':
        return 'WIMS 프로그램으로 요금연계를 진행합니다.'
      case 'SAEOL':
        return ' 새올 프로그램으로 요금연계를 진행합니다.'
      case 'BLUEWATER':
        return '푸른물 프로그램으로 요금연계를 진행합니다.'
      case 'CREBIZ':
        return '크레비즈 프로그램으로 요금연계를 진행합니다.'
      default:
        return '요금프로그램을 선택해주세요.'
    }
  }

  /**
   *
   * @param server env에 적힌 지역
   * @param officeValue 사업소 선택
   * @returns 두번째 step에서 뒤로 가기 활성화 유무
   */
  const prevHandler = (server: string, officeValue: string) => {
    switch (server) {
      case 'boseong':
      case 'eumseong':
      case 'cheonan':
      case 'suncheon':
        return false
      case 'dev':
      case 'prod':
        switch (officeValue) {
          case 'B7':
          case 'B700': //칠곡
            return false
          default:
            return true
        }
      default:
        return true
    }
  }

  /**
   *
   * @param server env에 적힌 지역
   * @returns datepicker 라벨 이름
   */
  const datePickerLabelHandler = (server: string) => {
    switch (server) {
      case 'gyeryong':
        return '검침일자'
      case 'dev':
      default:
        return '납기일자'
    }
  }
  /**
   *
   * @param server env에 적힌 지역
   * @returns datepicker 선택시 년,월,일 선택 규모
   */
  const datePickerViewsHandler = (server: string): CalendarPickerView[] => {
    switch (server) {
      case 'gyeryong':
        return ['year', 'month', 'day']
      default:
        return ['year', 'month']
    }
  }

  /**
   *
   * @param program 선택 요금 프로그램
   * @param server  env에 적힌 지역
   * @param e  event
   * selectedDate 형태 선택
   */
  const datePickerOnChangeHandler = (program: string, server: string, e: any) => {
    let selectedDate = ''
    switch (program) {
      case 'WIMS':
        switch (server) {
          case 'gyeryong':
            selectedDate = dayjs(e).format('YYYYMMDD')
            break
          case 'suncheon':
            selectedDate = dayjs(e).format('YYYYMM')
            break

          default:
            selectedDate = dayjs(e).endOf('month').format('YYYYMMDD')
            break
        }
        break
      default:
        selectedDate = dayjs(e).endOf('month').format('YYYYMM')
        break
    }
    setSelectInfo({
      ...selectInfo,
      selectDate: selectedDate,
    })
  }

  /**
   *
   * @param server env에 적힌 지역
   * @param day 선택 날짜
   * @returns 날짜 선택시 일 선택도 필요한 경우
   */
  const datePickerShouldDisabledHandler = (server: string, day: number) => {
    switch (server) {
      // 계룡은 5일, 15일만
      case 'gyeryong':
        return day !== 1 && day !== 15
      default:
        return false
    }
  }

  /**
   *
   * @param program 선택 요금
   * @returns datepicker 날짜 포맷
   */
  const datePickerInputFormatHandler = (program: string, server: string) => {
    switch (program) {
      case 'WIMS':
        switch (server) {
          case 'suncheon':
            return 'YYYY년 MM월'
          default:
            return 'YYYY년 MM월 DD일'
        }
      default:
        return 'YYYY년 MM월'
    }
  }

  /**
   *
   * @param program 선택 요금 프로그램
   * @returns 파일 업로드 기능 유무
   */
  const fileSelectHandler = (program: string, server: string, officeValue: string) => {
    switch (program) {
      case 'SAEOL':
        switch (server) {
          case 'eumseong':
            return true
          default:
            return false
        }
      case 'WIMS':
        switch (server) {
          case 'suncheon':
            return true
          default:
            return false
        }

      case 'CREBIZ':
        switch (server) {
          case 'prod':
          case 'dev':
            switch (officeValue) {
              case 'B700':
              case 'B7':
                return true
              default:
                return false
            }

          default:
            return false
        }

      default:
        return false
    }
  }

  const renderDateSelectMessageHandler = (selectInfo: any, server: string) => {
    let message = ''
    const selectedDate = selectInfo.selectDate
    const selectedProgram = selectInfo.selectProgram
    switch (true) {
      case selectedDate === '':
        switch (server) {
          case 'gyeryong':
            message = '검침일자를 선택해 주세요.'
            break
          default:
            message = '납기일자를 선택해 주세요.'
            break
        }
        break

      case selectedProgram === 'WIMS':
        if (server === 'suncheon') {
          message = `${dayjs(selectedDate).format('YYYY년 MM월')} 납기일로 요금연계를 진행합니다.`
        } else {
          message = `${dayjs(selectedDate).format(
            'YYYY년 MM월 DD일'
          )} 납기일로 요금연계를 진행합니다.`
        }

        break

      default:
        message = `${dayjs(selectedDate).format('YYYY년 MM월')} 납기일로 요금연계를 진행합니다.`
        break
    }

    return message
  }

  const chargeNavationHandler = async (selectedInfo: any, serverName: any, officeValue: string) => {
    const {selectProgram, selectDate} = selectedInfo
    let programName = ''
    let formattedDate = ''

    switch (selectProgram) {
      case 'WIMS':
        programName = 'WIMS'

        switch (serverName) {
          case 'suncheon':
            formattedDate = dayjs(selectDate).format('YYYY년 MM월')
            break
          default:
            formattedDate = dayjs(selectDate).format('YYYY년 MM월 DD일')
            break
        }
        break

      case 'SAEOL':
        programName = '새올'
        formattedDate = dayjs(selectDate).format('YYYY년 MM월')
        break

      case 'CREBIZ':
        programName = '크레비즈'
        formattedDate = dayjs(selectDate).format('YYYY년 MM월')
        break

      case 'BLUEWATER':
        programName = '푸른물'
        formattedDate = dayjs(selectDate).format('YYYY년 MM월')
        break

      default:
        programName = '새올'
        formattedDate = dayjs(selectDate).format('YYYY년 MM월')
        break
    }

    const confirmed = await playConfirm(
      `${userInfo?.officeName}의 요금연계`,
      `요금프로그램 : ${programName} / 납기일 : ${formattedDate}`,
      'info',
      '요금연계 진행'
    )

    if (confirmed) {
      switch (selectProgram) {
        case 'SAEOL':
          // 파일 없으면 SAEOL1 , 있으면 SAEOL
          const programQuery = fileName === '' ? `${selectProgram}1` : selectProgram

          switch (serverName) {
            case 'eumseong':
              await postFileData()
              if (!loading) {
                await navigate(`/charge/info?program=${selectProgram}&date=${selectDate}`)
              }

              break

            case 'boseong':
              if (!loading) {
                await navigate(`/charge/info?program=${selectProgram}&date=${selectDate}`)
              }
              break

            case 'prod':
            case 'dev':
              switch (officeValue) {
                case '76':
                case '7602':
                  setSelectInfo({selectProgram: '', selectDate: ''})
                  setSelectStep({...selectStep, step2: true})
                  await navigate(`/charge/list?program=${programQuery}&date=${selectDate}`)
                  break
                default:
                  setSelectInfo({selectProgram: '', selectDate: ''})
                  setSelectStep({...selectStep, step2: true})
                  await navigate(`/charge/list?program=${programQuery}&date=${selectDate}`)
                  break
              }
              break

            default:
              postFileData()
              setSelectInfo({selectProgram: '', selectDate: ''})
              setSelectStep({...selectStep, step2: true})
              await navigate(`/charge/list?program=${programQuery}&date=${selectDate}`)
              break
          }
          break

        case 'WIMS':
          switch (serverName) {
            case 'cheonan':
              await navigate(`/charge/info?program=${selectProgram}&date=${selectDate}`)
              break

            case 'suncheon':
              postFileData()
              await navigate(`/charge/info?program=${selectProgram}&date=${selectDate}`)
              break

            default:
              setSelectInfo({selectProgram: '', selectDate: ''})
              setSelectStep({...selectStep, step2: true})
              await navigate(`/charge/list?program=${programQuery}&date=${selectDate}`)
              break
          }

          break
        case 'CREBIZ':
          switch (serverName) {
            case 'prod':
            case 'dev':
              switch (officeValue) {
                case 'B7':
                case 'B700': //칠곡
                  await postFileData()
                  if (!loading) {
                    await navigate(`/charge/info?program=${selectProgram}&date=${selectDate}`)
                  }
                  break
                default:
                  setSelectInfo({selectProgram: '', selectDate: ''})
                  setSelectStep({...selectStep, step2: true})
                  await navigate(`/charge/list?program=${programQuery}&date=${selectDate}`)
                  break
              }
              break

            default:
              setSelectInfo({selectProgram: '', selectDate: ''})
              setSelectStep({...selectStep, step2: true})
              await navigate(`/charge/list?program=${selectProgram}&date=${selectDate}`)
              break
          }
          break

        default:
          setSelectInfo({selectProgram: '', selectDate: ''})
          setSelectStep({...selectStep, step2: true})
          await navigate(`/charge/list?program=${selectProgram}&date=${selectDate}`)
          break
      }
    }
  }

  const fileUploadAcceptHandler = (serverName: any, officeValue: any) => {
    switch (serverName) {
      case 'eumseong':
        return '.txt'
      case 'suncheon':
        return '.xlsx, .xls, .csv'
      case 'prod':
      case 'dev':
        switch (officeValue) {
          case 'B700':
          case 'B7':
            return '.xlsx, .xls, .csv'
          default:
            return ''
        }
      default:
        return ''
    }
  }

  useEffect(() => {
    selectStepHandler('start', serverName, officeInfo.value)
  }, [])

  return (
    <>
      {!selectStep.step1 && !selectStep.step2 && (
        <div className='card flex-grow-1' id='customer-register'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                <strong className={'text-primary'}>{officeInfo?.label}</strong> 요금연계프로그램
                선택
              </h3>
            </div>
          </div>
          <div className={'card-body align-self-center w-100 d-flex justify-content-center'}>
            <div className={' w-25'} style={{top: '30%', position: 'fixed'}}>
              <FormControl fullWidth className={'mb-4'}>
                <InputLabel id='demo-simple-select-label'>요금 프로그램</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='charge-program-select'
                  label='요금 프로그램'
                  value={selectInfo.selectProgram}
                  onChange={(e) => {
                    setSelectInfo({...selectInfo, selectProgram: e.target.value})
                  }}
                >
                  <MenuItem value={'WIMS'}>유니버스 ( WIMS )</MenuItem>
                  <MenuItem value={'SAEOL'}>시군구 ( 새올 )</MenuItem>
                  <MenuItem value={'BLUEWATER'}>한일 ( 푸른물 )</MenuItem>
                  <MenuItem value={'CREBIZ'}>크레비즈</MenuItem>
                </Select>
              </FormControl>
              <div className={'d-flex justify-content-between'}>
                {selectInfo.selectProgram === '' ? (
                  <span className={'text-danger fs-4 fw-bold'}>요금프로그램을 선택해주세요.</span>
                ) : (
                  <span className={'text-primary fs-4 fw-bold'}>
                    {selectedProgram(selectInfo.selectProgram)}
                  </span>
                )}
                <Button
                  variant={'contained'}
                  color={'primary'}
                  sx={{fontSize: '20px'}}
                  onClick={() => {
                    setSelectStep({...selectStep, step1: true})
                  }}
                  disabled={selectInfo.selectProgram === null}
                >
                  다음
                </Button>
              </div>
            </div>
          </div>
          <footer>
            {/* <div className='d-flex justify-content-center align-self-center'>
              <a className='fw-bold fs-5 m-4'>원격지원시스템</a>
            </div> */}
          </footer>
        </div>
      )}
      {selectStep.step1 === true && !selectStep.step2 && (
        <Slide
          direction='left'
          in={selectStep.step1 === true && !selectStep.step2}
          mountOnEnter
          unmountOnExit
          timeout={700}
        >
          <div className='card flex-grow-1' id='customer-register'>
            <div className='card-header'>
              <div className='card-title m-0'>
                {prevHandler(serverName, officeInfo.value) && (
                  <ArrowBackIosNewIcon
                    className={'me-3 cursor-pointer'}
                    onClick={() => {
                      setSelectStep({...selectStep, step1: false})
                      setSelectInfo({
                        ...selectInfo,
                        selectDate: '',
                      })
                    }}
                  />
                )}
                <h3 className='fw-bolder m-0'>
                  <strong className={'text-primary'}>{officeInfo?.label}</strong> 납기일자 선택
                </h3>
              </div>
            </div>
            <div className={'card-body w-100 d-flex justify-content-center'}>
              <div className={' w-25'} style={{top: '30%', position: 'fixed'}}>
                {fileSelectHandler(selectInfo?.selectProgram, serverName, officeInfo?.value) && (
                  <div className='row mb-xl-15'>
                    <div className='d-flex'>
                      <TextField
                        variant='outlined'
                        sx={{caretColor: 'transparent', width: '80%'}}
                        value={fileName === '' ? '파일을 선택해주세요.' : fileName}
                      />
                      <div className='d-flex w-25'>
                        <Button
                          className='w-100 fs-2'
                          variant='contained'
                          color='success'
                          component='label'
                        >
                          파일 선택
                          <input
                            type='file'
                            id='fileItems'
                            accept={fileUploadAcceptHandler(serverName, officeInfo?.value)}
                            onChange={(e) => fileUploadHandler(e)}
                            hidden
                          />
                        </Button>
                      </div>
                    </div>
                    {fileName === '' ? (
                      <span className='text-danger fs-4 fw-bold mt-3'> 파일을 선택해주세요.</span>
                    ) : (
                      <span className='text-primary fs-4 fw-bold mt-3'>
                        {fileName} 파일로 요금연계를 진행합니다.
                      </span>
                    )}
                  </div>
                )}
                <div className={'mb-4'}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={datePickerLabelHandler(serverName)}
                      minDate={dayjs().subtract(3, 'month')}
                      views={datePickerViewsHandler(serverName)} //feature/sc 23.12.19
                      openTo='month'
                      shouldDisableDate={(date) => {
                        const day = dayjs(date).date()
                        return datePickerShouldDisabledHandler(serverName, day)
                      }}
                      onChange={(e) => {
                        datePickerOnChangeHandler(selectInfo?.selectProgram, serverName, e)
                      }}
                      value={selectInfo?.selectDate}
                      inputFormat={datePickerInputFormatHandler(
                        selectInfo?.selectProgram,
                        serverName
                      )}
                      renderInput={(params: any) => (
                        <TextField fullWidth size='large' {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className={'d-flex justify-content-between'}>
                  <span
                    className={
                      selectInfo.selectDate === ''
                        ? 'text-danger fs-4 fw-bold'
                        : 'text-primary fs-4 fw-bold'
                    }
                  >
                    {renderDateSelectMessageHandler(selectInfo, serverName)}
                  </span>

                  <Button
                    variant={'contained'}
                    color={'primary'}
                    sx={{fontSize: '20px'}}
                    onClick={async () => {
                      chargeNavationHandler(selectInfo, serverName, officeInfo.value)
                      // if (selectInfo.selectProgram === 'WIMS') {
                      //   if (
                      //     await playConfirm(
                      //       `${userInfo?.officeName}의 요금연계`,
                      //       `요금프로그램 : ${selectInfo.selectProgram} / 납기일 : ${dayjs(
                      //         selectInfo.selectDate
                      //       ).format('YYYY년 MM월 DD일')}`,
                      //       'info',
                      //       '요금연계 진행'
                      //     )
                      //   ) {
                      //     setSelectStep({...selectStep, step2: true})
                      //     navigate(
                      //       `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                      //     )
                      //   }
                      // } else if (selectInfo.selectProgram === 'SAEOL') {
                      //   if (
                      //     await playConfirm(
                      //       `${userInfo?.officeName}의 요금연계`,
                      //       `요금프로그램 : ${
                      //         selectInfo.selectProgram === 'BLUEWATER' ? '푸른물' : '새올'
                      //       } / 납기일 : ${dayjs(selectInfo.selectDate).format('YYYY년 MM월')}`,
                      //       'info',
                      //       '요금연계 진행'
                      //     )
                      //   ) {
                      //     setSelectStep({...selectStep, step2: true})
                      //     setSelectInfo({selectProgram: '', selectDate: ''})
                      //     postFileData()
                      //     if (fileName === '') {
                      //       console.log('nofile', selectInfo.selectProgram)
                      //       await navigate(
                      //         `/charge/list?program=${selectInfo.selectProgram}1&date=${selectInfo.selectDate}`
                      //       )
                      //     } else {
                      //       console.log('yesfile', selectInfo.selectProgram)
                      //       await navigate(
                      //         `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                      //       )
                      //     }
                      //   }
                      // } else if (selectInfo.selectProgram === 'CREBIZ') {
                      //   if (
                      //     await playConfirm(
                      //       `${userInfo?.officeName}의 요금연계`,
                      //       `요금프로그램 : 크레비즈 / 납기일 : ${dayjs(
                      //         selectInfo.selectDate
                      //       ).format('YYYY년 MM월')}`,
                      //       'info',
                      //       '요금연계 진행'
                      //     )
                      //   ) {
                      //     setSelectStep({...selectStep, step2: true})
                      //     setSelectInfo({selectProgram: '', selectDate: ''})
                      //     await navigate(
                      //       `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                      //     )
                      //   }
                      // } else {
                      //   if (
                      //     await playConfirm(
                      //       `${userInfo?.officeName}의 요금연계`,
                      //       `요금프로그램 : ${
                      //         selectInfo.selectProgram === 'BLUEWATER' ? '푸른물' : '새올'
                      //       } / 납기일 : ${dayjs(selectInfo.selectDate).format('YYYY년 MM월')}`,
                      //       'info',
                      //       '요금연계 진행'
                      //     )
                      //   ) {
                      //     setSelectStep({...selectStep, step2: true})
                      //     setSelectInfo({selectProgram: '', selectDate: ''})
                      //     navigate(
                      //       `/charge/list?program=${selectInfo.selectProgram}&date=${selectInfo.selectDate}`
                      //     )
                      //   }
                      // }
                    }}
                    disabled={selectInfo.selectDate === ''}
                  >
                    다음
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      )}
    </>
  )
}

export default ChargeSelect
